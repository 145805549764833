import { createContext, useState } from 'react'

import moment from 'moment-timezone'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { gql, useMutation, useQuery } from '@apollo/client'

import { CircularProgress } from '@mui/material'

import './App.css'
import {} from './Roboto-Bold-normal'
import {} from './Roboto.ttf-normal'
import Home from './home/Home'
import icuGb from './icuGb'
import icuMk from './icuMk'
import QrGenerator from './qr/QrGenerator'
import { Restaurant } from './Restaurant'
import * as translation_en from './restaurant/common/language/en'
import * as translation_mk from './restaurant/common/language/mk'
import ChangeEmail from './signin/ChangeEmail'
import ForgotPassword from './signin/ForgotPassword'
import Register from './signin/Register'
import ResetPassword from './signin/ResetPassword'
import SignIn from './signin/SignIn'

export const RestaurantContext = createContext({})
export const UserContext = createContext({
  lang: 'mk',
  formatDate: (date, datetime = false, format = false) => '',
  translate: (key, ...params) => '',
})

window.columnParents = window.columnParents || []
window.columnNumbers = window.columnNumbers || []

export default function App({ theme }) {
  const [language, setLanguage] = useState('mk')
  const [refetchIt, setRefetchIt] = useState(false)
  const [restLang, setRestLang] = useState('mk')

  const EDIT_USER = gql`
    mutation ($id: String!, $lang: String!) {
      editUser(input: { id: $id, lang: $lang }) {
        id
      }
    }
  `
  const [editUser, { loading: loading_edit }] = useMutation(EDIT_USER)

  const ME = gql`
    {
        me {
            _id
            id
            lang
            profile {
                _id
                userId
                name
                email
                picture
                fbAccessToken
                fbId
                googleId
                birthday
                isAdmin
                phone
                isEmailVerified
            }
        }
        extras_userRestaurants{
            restaurantId
            role
            employeeId
            restaurant {
                _id
                id
                name { ${language} }
                imageLogo
                imageLogoBg
                address
                lang
                phone
                legalInfo
                restaurantBillings {
                    id
                    bankName
                    bankAccount
                }
                subscription
                pltSalePrice
            }
        }
    }`

  const { data, loading, refetch } = useQuery(ME, {
    fetchPolicy: 'network_only',
    errorPolicy: 'ignore',
  })

  if (loading) {
    return (
      <div className="App AppLoading">
        <CircularProgress />
      </div>
    )
  }

  const me = data && data.me && data.me.profile ? data.me : false
  const role =
    data && data.me && data.extras_userRestaurants && data.extras_userRestaurants.length > 0 ? data.extras_userRestaurants[0].role : false
  if (
    restLang &&
    data &&
    data.me &&
    data.extras_userRestaurants &&
    data.extras_userRestaurants.length > 0 &&
    data.extras_userRestaurants[0].restaurant.lang.toLowerCase() !== restLang
  ) {
    setRestLang(data.extras_userRestaurants[0].restaurant.lang.toLowerCase())
  }

  if (me && language !== me.lang.toLowerCase()) {
    setLanguage(me.lang.toLowerCase())
  }

  const onSetLanguage = (lang) => {
    setLanguage(lang)

    const items = {
      id: me.id,
      lang: lang.toLowerCase(),
    }

    editUser({ variables: items })
    setRefetchIt(true)
  }

  const onLogin = () => {
    setRefetchIt(true)
  }

  if (!loading && !loading_edit && refetchIt) {
    setRefetchIt(false)
    refetch()
  }

  const translation = language === 'mk' ? translation_mk.default : translation_en.default
  document.documentElement.setAttribute('lang', language) // set html tag attribute lang to language

  const icu = restLang === 'mk' ? icuMk : icuGb

  const gqlCreateName = (nameVar, id = false, type = false) => {
    return (
      `create${type || 'Name'}: {` +
      (id ? `id: ${id}, ` : '') +
      Array.from(new Set(['en', 'mk', language]))
        .map((lang) => `${lang}: ${nameVar}`)
        .join(', ') +
      `}`
    )
  }
  const gqlCreateNameTr = (mkVar, enVar, type = false) => {
    return (
      `create${type || 'Name'}: {` +
      Array.from(new Set(['en', 'mk']))
        .map((lang) => `${lang}: ${lang === 'mk' ? mkVar : enVar}`)
        .join(', ') +
      `}`
    )
  }
  const gqlEditName = (nameVar, id, type = false) => {
    return (
      `edit${type || 'Name'}: {id: ${id}, ` +
      Array.from(new Set([language]))
        .map((lang) => `${lang}: ${nameVar}`)
        .join(', ') +
      `}`
    )
  }
  const gqlEditNameTr = (mkVar, enVar, id, type = false) => {
    return (
      `edit${type || 'Name'}: {id: ${id}, ` +
      Array.from(new Set(['en', 'mk']))
        .map((lang) => `${lang}: ${lang === 'mk' ? mkVar : enVar}`)
        .join(', ') +
      `}`
    )
  }
  const gqlFetchName = () =>
    Array.from(new Set(['id', language, 'en', 'mk']))
      .map((lang) => `${lang}`)
      .join(', ')

  const dateTimeFormatDisplay = 'DD.MM.yyyy HH:mm'
  const dateFormatDisplay = 'DD.MM.yyyy'
  const dateTimeFormat = 'dd.MM.yyyy HH:mm'
  const dateFormat = 'dd.MM.yyyy'

  const formatDate = (date, datetime = false, format = false) => {
    if (format) return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format)
    else if (datetime) return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(dateTimeFormatDisplay)
    else return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(dateFormatDisplay)
  }

  const formatNumber = (number, percent = false, currency = false, fractions = 2) => {
    const options = {
      style: currency ? 'currency' : percent ? 'percent' : 'decimal',
      maximumFractionDigits: fractions,
    }
    if (currency) options.currency = currency
    if (number === undefined || number === null) return ''
    return percent ? new Intl.NumberFormat(restLang, options).format(number) : icu.getDecimalFormat(fractions).format(number)
  }
  const formatQuantity = (number, fractions = 3, zero) => {
    if (number === 0 && zero !== undefined) return zero
    if (number === null || number === undefined) return ''
    return icu.getDecimalFormat(fractions).format(number, true)
  }
  const formatQuantitySign = (number, fractions = 3, zero) => {
    if (number === 0) return zero !== undefined ? zero : '0'
    const sign = number > 0 ? '+' : '-'
    return sign + icu.getDecimalFormat(fractions).format(Math.abs(number), true)
  }
  const formatQuantityReport = (number, fractions = 3) => {
    return icu.getDecimalFormat(fractions).format(number, true, true)
  }

  const parseStringNumber = (string) => {
    const parsed = restLang === 'mk' ? string.replaceAll('.', '').replace(',', '.') : string.replaceAll(',', '')

    return parseFloat(parsed)
  }

  const consoleLog = (param, row = false) => {
    // console.trace(2);
    // console.log((new Error().stack).split('\n')[1])
    console.log(new Error().stack.split('\n')[2])
    if ((me && me.profile.isAdmin) || !me) {
      if (row) console.log(param, 'row: ' + row)
      else console.log(param)
    }
  }

  // console.log(formatQuantityReport(11234.34));

  const translate = (key, ...params) => {
    const value = translation[key] || key
    return params.reduce((result, param) => result.replace('%s', param), value)
  }

  return (
    <UserContext.Provider
      value={{
        me: me || undefined,
        role,
        lang: language,
        restLang,
        consoleLog,
        setRestLang,
        gqlFetchName,
        gqlCreateName,
        gqlCreateNameTr,
        gqlEditName,
        gqlEditNameTr,
        translate,
        formatDate,
        dateFormat,
        dateTimeFormat,
        formatNumber,
        formatQuantity,
        formatQuantitySign,
        formatQuantityReport,
        parseStringNumber,
        icu,
      }}
    >
      <div className="App">
        <Router basename={process.env.REACT_APP_BASENAME}>
          <Routes>
            <Route exact path="/" element={<Navigate to={{ pathname: me ? '/home' : '/login' }} />} />
            <Route
              exact
              path="/home"
              element={
                me ? (
                  <Home restaurants={data.extras_userRestaurants} onSetLanguage={onSetLanguage} refetch={onLogin} />
                ) : (
                  <Navigate to={{ pathname: '/login' }} />
                )
              }
            />
            <Route
              path="/restaurant/:restaurantId/*"
              element={
                me && role > 1 ? (
                  <Restaurant
                    theme={theme}
                    restaurants={me && data.extras_userRestaurants}
                    restLang={restLang}
                    setRestLang={setRestLang}
                    onSetLanguage={onSetLanguage}
                    refetch={onLogin}
                  />
                ) : (
                  <Navigate to={{ pathname: '/login' }} />
                )
              }
            />
            <Route path="/login" element={me ? <Navigate to={{ pathname: '/home' }} /> : <SignIn onLogin={onLogin} />} />
            <Route path="/register" element={me ? <Navigate to={{ pathname: '/home' }} /> : <Register onLogin={onLogin} />} />
            <Route path="/forgot-password" element={me ? <Navigate to={{ pathname: '/home' }} /> : <ForgotPassword onLogin={onLogin} />} />
            <Route path="/reset-pass/:code" element={<ResetPassword refetch={onLogin} />} />
            <Route path="/change-email/:code" element={<ChangeEmail refetch={onLogin} />} />
            <Route path="/qr-generator" element={me?.profile?.isAdmin ? <QrGenerator /> : <div>Errorr</div>} />
            <Route element={<p>Page Not Found</p>} />
          </Routes>
        </Router>
      </div>
    </UserContext.Provider>
  )
}
